import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Head from '../components/Head/head';
import InfoEstatica from '../components/InfoEstatica'

export default function Comercial() {
    return (
        <>
            <Head />
            <InfoEstatica
                Colaborador={`Comercial Vega`}
                Imagem={ <StaticImage title="Comercial Vega I.T." placeholder='blurred' loading='lazy' className='info-image' src='../images/home/veganauta.png' alt='veganauta' />}
                Cargo={`Comercial`}
                Email={`comercial@vegait.com`}
                Whatsapp={`41991565425`}
                Telefone={`41991565425`}
                Contato={`BEGIN:VCARD VERSION:3 FN;CHARSET=UTF-8:Suporte Vega I.T. N;CHARSET=UTF-8:I.T.;Suporte;Vega;;EMAIL;CHARSET=UTF-8;type=HOME,INTERNET:suporte@vegait.com TEL;TYPE=HOME,VOICE:4132040300 ORG;CHARSET=UTF-8:Vega I.T. URL;type=WORK;CHARSET=UTF-8:https://vegait.com/ X-SOCIALPROFILE;TYPE=facebook:https://www.facebook.com/vegait X-SOCIALPROFILE;TYPE=linkedin:https://www.linkedin.com/company/vegait X-SOCIALPROFILE;TYPE=instagram:https://www.instagram.com/vegait/ X-SOCIALPROFILE;TYPE=youtube:https://www.youtube.com/c/VEGAITtecnologia REV:2022-12-08T16:59:05.360Z END:VCARD`}
            />
        </>
    )
}
